import React from "react";
import {
  Table,
  CloseButton,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import "react-tabs/style/react-tabs.css";
import { useTable, useSortBy, useFilters } from "react-table";
import ReactModal from "react-modal";
import API from "../apienv";
import { matchSorter } from "match-sorter";

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const Tables = ({
  d,
  c,
  addModal,
  approveNominee,
  modalIsOpen,
  setIsOpen = () => { },
}) => {
  const [openNominee, setOpenNominee] = React.useState(false);
  const [status, setStatus] = React.useState(0);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  function openModal(nominee) {
    setIsOpen(true);
    const recipeObj = nominee?.row["original"];
    setOpenNominee(recipeObj);
  }

  function closeModal() {
    setIsOpen(false);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = React.useMemo(() => d, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = React.useMemo(() => c, []);

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
      },
      useFilters,
      useSortBy
    );

  const submit = async (e) => {
    e.preventDefault();

    await approveNominee(openNominee._id, status).then((success) => {
      if (success) {
        closeModal();
        // setSubmitted(true);
      } else {
        // setError(true);
      }
    });
  };

  return (
    <>
      <Table {...getTableProps()} striped bordered hover>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  style={{
                    padding: "1vw",
                    minWidth: "150px",
                  }}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : " ↕"}
                  </span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      style={{
                        cursor: addModal && "pointer",
                        padding: "1vw",
                      }}
                      onClick={() => {
                        openModal(cell);
                      }}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {openNominee && addModal && (
        <ReactModal
          ariaHideApp={false}
          key={openNominee}
          tabindex="-1"
          role="dialog"
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          class="modal"
          overlayClassName="_"
          contentElement={(props, children) => (
            <div {...props} style={{ ...props.style, position: 'fixed', inset: 0, padding: '60px' }}>
              <Modal.Header style={{ justifyContent: "space-between" }}>
                <h4></h4>
                <CloseButton
                  onClick={() => {
                    closeModal();
                  }}
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                />
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={submit}>
                  {(openNominee.fileName && typeof openNominee.fileName === 'string' && openNominee.fileName.endsWith('.pdf'))
                    ? (
                      <a href={`${API}/${openNominee.fileName}`} download target="_blank" rel="noreferrer">
                        {openNominee.fileName}
                      </a>
                    ) : (
                      <img
                        style={{ maxWidth: "1000px", width: "100%", height: "auto", margin: "0 auto" }}
                        src={`${API}/${openNominee.fileName}`}
                        alt="nominee"
                      />)}

                  <ul style={{ marginTop: '40px', marginBottom: '100px' }}>
                    <li><strong>Name:</strong> {openNominee.name}</li>
                    <li><strong>Name of the nominee:</strong> {openNominee.nomineeName}</li>
                    <li><strong>Email:</strong> {openNominee.email}</li>
                    <li><strong>Phone:</strong> {openNominee.phone}</li>
                    <li><strong>Text:</strong> {openNominee.text}</li>
                    <li><strong>CompetitionId:</strong> {openNominee.competitionId}</li>
                  </ul>

                  {/* {allowEdit && ( */}
                  <Modal.Footer style={{ padding: '40px 20px', justifyContent: "flex-start", borderTop: "1px solid" }}>
                    <Button
                      variant="dark"
                      style={{
                        backgroundColor: "#ACAF2E",
                        border: "#ACAF2E",
                      }}
                      type="submit"
                      onClick={() => setStatus('approved')}
                    >
                      Godkänn
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#ad2e2e",
                        border: "#ad2e2e",
                        marginLeft: "20px"
                      }}
                      variant="dark"
                      type="submit"
                      onClick={() => setStatus('rejected')}
                    >
                      Neka
                    </Button>
                    <Button
                      onClick={closeModal}
                      style={{
                        backgroundColor: "#ebc634",
                        border: "#ad2e2e",
                        marginLeft: "20px"
                      }}
                    >
                      Stäng
                    </Button>
                  </Modal.Footer>
                  {/* )} */}
                </Form>
              </Modal.Body>
            </div>
          )}
        ></ReactModal>
      )}
    </>
  );
};

export default Tables;
