import React, { useContext, useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import Tables from "./Tables.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthContext } from "../auth.jsx";
import Space from "./Space.jsx";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Stack } from "react-bootstrap";
import { formatDataArray } from "../helpers.js";
import API from "../apienv";

const idToStatus = (id) => {
  switch (id) {
    case 0:
      return "pending";
    case 1:
      return "approved";
    case 2:
      return "rejected";
    default:
      return "pending";
  }
};

const Nominees = () => {
  const { currentUser } = useContext(AuthContext);
  const [adminData, setAdminData] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const getData = async (id) => {
    const token = await currentUser.getIdToken();
    const status = idToStatus(id);
    return await fetch(API + "/admin/nominees/filter/" + status, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const formatedResponse = formatDataArray(response);
        setAdminData(formatedResponse);
      })
      .catch((err) => {
        console.log("Error:", err);
        // setData({ ...data, loading: false });
      });
  };

  const approveNominee = async (nomineeId, status) => {
    const token = await currentUser.getIdToken();

    return await fetch(API + "/admin/nominees/update/" + nomineeId, {
      method: "POST",
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer",
      body: JSON.stringify({ status }),
    })
      .then((response) => {
        setAdminData(adminData.filter((item) => item._id !== nomineeId));
      })
      .catch((err) => {
        console.log(err);
        return false; // DEV NOTE: Set to true to test thanks you view before endpoint exist
      });
  };

  useEffect(() => {
    getData(tabIndex);
  }, [tabIndex]);

  const theTable = (
    <Tables
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      key={adminData}
      approveNominee={approveNominee}
      d={adminData}
      addModal
      c={[
        {
          Header: "Name",
          accessor: "name", // accessor is the "key" in the data
        },
        {
          Header: "Nominee name",
          accessor: "nomineeName", // accessor is the "key" in the data
        },
        {
          Header: "Email",
          accessor: "email", // accessor is the "key" in the data
        },
        {
          Header: "Phone",
          accessor: "phone", // accessor is the "key" in the data
        },
        {
          Header: "Status",
          accessor: "status",
        },
        {
          Header: "Skapad",
          accessor: "created_at",
        },
      ]}
    />
  );

  return (
    <>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList style={{ padding: "40px 0", backgroundColor: "" }}>
          <Stack direction="horizontal" gap={4}>
            <Tab>Att godkänna</Tab>
            <Tab>Godkända</Tab>
            <Tab>Nekade</Tab>
          </Stack>
        </TabList>
        <Space />
        <TabPanel>{adminData && <>{theTable}</>}</TabPanel>
        <TabPanel>{adminData && <>{theTable}</>}</TabPanel>
        <TabPanel>{adminData && <>{theTable}</>}</TabPanel>
      </Tabs>
    </>
  );
};

export default Nominees;
