import React, { useEffect, useState, useContext } from 'react';
import API from "../apienv";
import { AuthContext } from "../auth.jsx";
import Tables from "./Tables.jsx";

const headers = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Skapad",
    accessor: "created_at",
  },
]

const Lottery = ({ competitionId }) => {

  const { currentUser } = useContext(AuthContext);

  const [signups, setSignups] = useState(false);

  const getData = async () => {
    const token = await currentUser.getIdToken();
    const response = await fetch(`${API}/admin/signups/${competitionId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData = await response.json();
    setSignups(responseData);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {signups && (
        <Tables
          c={headers}
          d={signups}
        />
      )}
    </>
  )

};

export default Lottery;