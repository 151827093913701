import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import { AuthProvider } from "./auth";
import { StatisticsProvider } from "./StatisticsContext";

import "./App.css";

const App = () => {
  return (
    <AuthProvider>
      <StatisticsProvider>
        <Router>
          <div>
            <Routes />
          </div>
        </Router>
      </StatisticsProvider>
    </AuthProvider>
  );
};

export default App;
