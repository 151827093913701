import React, { useContext } from "react";
import { AuthContext } from "../../auth";
import { Navigate } from "react-router-dom";

const Auth = ({ children }) => {
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) return <Navigate to="/login" />;

  return <>{children}</>;
};

export default Auth;
