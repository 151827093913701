import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Navigate } from "react-router-dom";
import { Form, Button, Container } from "react-bootstrap";
import { ReactComponent as Logo } from "../logo.svg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
      setRedirect(true);
    } catch (e) {
      console.error(e.message);
      // Handle error
    }
  };

  if (redirect) return <Navigate to="/" />;

  return (
    <Container>
      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ margin: "0 auto 50px", width: "21%", color: "#fff" }}>MILJONLOTTERIET</div>
      </div>
      <Form onSubmit={submit} style={{ padding: "0 5vw 5vw" }}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label style={{ color: "white" }} className="">Email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label style={{ color: "white" }}>Lösenord</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Form.Group>

        <Button variant="dark" type="submit">
          Login
        </Button>
      </Form>
    </Container>
  );
};

export default Login;
