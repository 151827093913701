/* eslint-disable */
import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyADys10SL28V1WjGpozcmo12M-RQ2VdMf0",
  authDomain: "miljonlotteriet-fa2e9.firebaseapp.com",
  projectId: "miljonlotteriet-fa2e9",
  storageBucket: "miljonlotteriet-fa2e9.appspot.com",
  messagingSenderId: "437746463457",
  appId: "1:437746463457:web:073fe7796d9e0403f1bda0"
};

export const AuthContext = React.createContext();
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState("loading");
  const [app, setApp] = useState(false);

  const init = async () => {
    const newApp = initializeApp(firebaseConfig);
    const auth = getAuth(newApp);
    await setPersistence(auth, browserSessionPersistence);
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    setApp(newApp);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        app,
      }}
    >
      {currentUser !== "loading" && children}
    </AuthContext.Provider>
  );
};
