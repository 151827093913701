import React, { useState, useCallback, useContext } from "react";
import { AuthContext } from "./auth";
import { API } from "./helpers";

const StatisticsContext = React.createContext();

const StatisticsProvider = ({ children }) => {
  const [data, setData] = useState([]);

  const { currentUser } = useContext(AuthContext);

  const getData = useCallback(
    async (e) => {
      setData({ ...data, loading: true });

      const token = await currentUser.getIdToken();

      const getReceipts0 = fetch(API + "/admin/receipts/0", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error("Error:", err);
          // setData({ ...data, loading: false });
        });

      const getReceipts1 = fetch(API + "/admin/receipts/1", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error("Error:", err);
          // setData({ ...data, loading: false });
        });

      const getReceipts2 = fetch(API + "/admin/receipts/2", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error("Error:", err);
          // setData({ ...data, loading: false });
        });

      Promise.all([getReceipts0, getReceipts1, getReceipts2])
        .then((values) => {
          let all = [...values[0], ...values[1], ...values[2]];

          const uniqueUser = all.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.uid === value.uid)
          );

          setData({
            ...data,
            approved: values[1].length,
            user: uniqueUser.length,
            recipes: all.length,
            loading: false,
          });
        })
        .catch((err) => {
          console.error("Error:", err);
        });
    },
    [data, currentUser]
  );

  return (
    <StatisticsContext.Provider
      value={{
        data,
        getData,
      }}
    >
      {children}
    </StatisticsContext.Provider>
  );
};

const useStatistics = () => React.useContext(StatisticsContext);

export { StatisticsProvider, useStatistics };
