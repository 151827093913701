import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Container, Button, Stack } from "react-bootstrap";
import { getAuth, signOut } from "firebase/auth";
import "bootstrap/dist/css/bootstrap.min.css";
import Space from "../components/Space.jsx";
import Nominees from "../components/Nominees.jsx";
import Lottery from "../components/Lottery.jsx";

const Home = () => {
  const logout = (e) => {
    e.preventDefault();
    const auth = getAuth();
    signOut(auth);
  };


  return (
    <>
      <Container>
        <div style={{ width: "100%", display: "flex" }}>
          <div style={{ margin: "0 auto 50px", width: "21%", color: "#fff" }}>MILJONLOTTERIET</div>
        </div>
        <Tabs>
          <TabList
            style={{ padding: "40px 0", backgroundColor: "", overflow: "auto" }}
          >
            <Stack direction="horizontal" gap={2}>
              <Tab>Bidrag</Tab>
              <Tab>Motorfreak</Tab>
              <Tab>Hundälskare</Tab>
              <Tab>Stickningsess</Tab>
              <Tab>Kattfantast</Tab>
              <Button className="ms-auto" variant="dark" onClick={logout}>
                Logga ut
              </Button>
            </Stack>
          </TabList>
          <Space />
          <TabPanel style={{ overflow: "auto" }}>
            <Nominees />
          </TabPanel>
          <TabPanel>
            <Lottery competitionId="sveriges-storsta-motorfreak" />
          </TabPanel>
          <TabPanel>
            <Lottery competitionId="sveriges-storsta-hundalskare" />
          </TabPanel>
          <TabPanel>
            <Lottery competitionId="sveriges-storsta-stickningsess" />
          </TabPanel>
          <TabPanel>
            <Lottery competitionId="sveriges-storsta-kattfantast" />
          </TabPanel>
        </Tabs>
        <Space height="5vw" />
      </Container>
    </>
  );
};

export default Home;
