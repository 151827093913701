import React from "react";
import Home from './views/Home';
import Login from './views/Login';
import Auth from './components/Auth';
import {
  Routes,
  Route,
} from "react-router-dom";

const RoutesSwitch = () => {
  return (
    <Routes>
      <Route
        exact
        path="/login"
        element={<Login />}
      />
      <Route
        exact
        path="/"
        element={(
        <Auth>
          <Home />
        </Auth>)}
        />
    </Routes>
  );
}

export default RoutesSwitch;
